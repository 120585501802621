

  
<div class="modal fade" id="participantForm" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="participantFormLabel" aria-hidden="true">
<div class="modal-dialog">
  <form [formGroup]="participantForm" (ngSubmit)="onSubmit()"> 
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="participantFormLabel">{{participantModel ? participantModel.first_name:'Add Participant'}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         
          <label for="company">Company *</label>
          <ng-select placeholder="Select a company" formControlName="company">
            <ng-option *ngFor="let company of companiesResponseModel.value.results" [value]="company.id">{{company.name}}</ng-option>
         </ng-select>

         <div *ngIf="
         participantForm.get('company')!.invalid && 
         (participantForm.get('company')!.dirty || 
         participantForm.get('company')!.touched)" 
         class="text-fix text-danger">
           <div *ngIf="participantForm.get('company')!.errors?.['required']">company is required.</div> 
         </div>
            

          <div class="form-group mt-2">
            <label for="first_name">First name *</label>
            <input type="text" class="form-control" id="first_name" formControlName="first_name"
            placeholder="John">
            <div *ngIf="
            participantForm.get('first_name')!.invalid && 
            (participantForm.get('first_name')!.dirty || 
            participantForm.get('first_name')!.touched)" 
            class="text-fix text-danger">
              <div *ngIf="participantForm.get('first_name')!.errors?.['required']">First name is required.</div> 
              <div *ngIf="participantForm.get('first_name')!.errors?.['minlength']">First name must be at least 1 characters long.</div>
            </div>
          </div>

          <div class="form-group mt-2">
            <label for="last_name">Last name *</label>
            <input type="text" class="form-control" id="last_name" formControlName="last_name"
            placeholder="Rockefeller">
            <div *ngIf="
            participantForm.get('last_name')!.invalid && 
            (participantForm.get('last_name')!.dirty || 
            participantForm.get('last_name')!.touched)" 
            class="text-fix text-danger">
              <div *ngIf="participantForm.get('last_name')!.errors?.['required']">Last name is required.</div> 
              <div *ngIf="participantForm.get('last_name')!.errors?.['minlength']">Last name must be at least 1 characters long.</div>
            </div>
          </div>



          <div class="form-group mt-2">
            <label for="title">Title *</label>
            <input type="text" class="form-control" id="title" formControlName="title"
            placeholder="CEO/VP..">
            <div *ngIf="
            participantForm.get('title')!.invalid && 
            (participantForm.get('title')!.dirty || 
            participantForm.get('title')!.touched)" 
            class="text-fix text-danger">
              <div *ngIf="participantForm.get('title')!.errors?.['required']">Title is required.</div> 
              <div *ngIf="participantForm.get('title')!.errors?.['minlength']">Title must be at least 2 characters long.</div>
            </div>
          </div> 

          <div class="form-group mt-2">
            <label for="email">Email *</label>
            <input type="text" class="form-control" id="email" formControlName="email"
            placeholder="email@domain.com">
            <div *ngIf="
            participantForm.get('email')!.invalid && 
            (participantForm.get('email')!.dirty || 
            participantForm.get('email')!.touched)" 
            class="text-fix text-danger">
              <div *ngIf="participantForm.get('email')!.errors?.['required']">Title is required.</div> 
              <div *ngIf="participantForm.get('email')!.errors?.['minlength']">Title must be at least 4 characters long.</div>
            </div>
          </div>

          <div class="form-group mt-2">
            <label for="linkedin_url">LinkedIn</label>
            <input type="url" class="form-control" id="linkedin_url" formControlName="linkedin_url" placeholder="https://www.linkedin.com/...">
          </div>


          <label class="mt-2">Phones</label>
          <ul class="list-group">
            <li class="list-group-item" aria-disabled="true" *ngFor="let p of phones">
              <div class="d-flex justify-content-between">
                <p class="m-0">({{getPhoneValue(p.country_code)}}) {{p.phone}}</p>
                <p class="m-0"><a class="text-danger" (click)="removePhone(p)" href="javascript:void(0)"><i class="fa-solid fa-circle-minus"></i></a></p>
              </div>
            </li>
          </ul>
          

          <a href="javascript:void(0)" class="link mt-4 text-decoration-none" data-bs-toggle="modal" data-bs-target="#addPhone">
            <i class="fa-solid fa-plus"></i> Add new phone
          </a>
         

      </div>
      <div class="modal-footer">
        <button type="button" id="participantModalCloseBtn" (click)="modalClosed()"
        class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary mt-3 ms-auto">{{participantModel ? 'Update Participant':'Add Participant'}}</button>
      </div>
    </div>
  </form>
  </div>
</div>

<!-- Button trigger modal -->


 

<!-- Modal -->
<div class="modal fade" id="addPhone" tabindex="-1" aria-labelledby="addPhoneLabel" aria-hidden="true"
data-bs-backdrop="static" data-bs-keyboard="false" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="addPhoneLabel">Add Phone</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body"> 
        <div class="" > 
            <label for="name" >Phone code</label>
                <ng-select placeholder="Select country phone code" [(ngModel)]="newPhone.country_code">
                  <ng-option *ngFor="let code of phoneCountryCodeResponseModel.value.results" [value]="code.id">
                    <span class="text-capitalize">{{code.country}}</span> ({{code.code}})</ng-option>
               </ng-select>
       
            <div class="form-group mt-2">
              <label for="name">Phone</label>
              <input type="number" class="form-control" id="phone" min="5" max="12" [(ngModel)]="newPhone.phone">
            </div>
           
      



        </div>
        

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="phoneAddedCancel()"
        data-bs-target="#participantForm" data-bs-toggle="modal"
        >Close</button>
        <button type="button" class="btn btn-primary ms-auto" (click)="phoneAdded()" 
        data-bs-target="#participantForm" data-bs-toggle="modal">Add</button>
      </div>
    </div>
  </div>
</div>