import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/api-providers/services/api-service';
import { EventsViewModel } from 'src/api-providers/view-models/events.view.model';

@Component({
  selector: 'app-modal-import-event-data',
  templateUrl: './modal-import-event-data.component.html',
  styleUrls: ['./modal-import-event-data.component.scss'],
  providers: [EventsViewModel, ApiService]
})
export class ModalImportEventDataComponent {

  selectedFile: File | null = null;
  @Input() eventId: number | null = null;
  @Output() formSuccessEvent = new EventEmitter<any>();
  @ViewChild('myFileInput') myFileInput: ElementRef;

  constructor(
    private eventVM: EventsViewModel
  ){

  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onFileUpload() {
    if (this.selectedFile) {
      this.eventVM.parentModalImportEventDataComponent = this;
      this.eventVM.importEventData(this.selectedFile, String(this.eventId));
    }
  }


  clearFileInput(){
    this.selectedFile = null;
    this.myFileInput.nativeElement.value = "";
  }

  importComplete(){ 
    this.clearFileInput();
    document.getElementById("importModalCloseBtn")?.click();
    this.formSuccessEvent.emit(true);
  }

  closeClicked(){
    this.clearFileInput();
    this.formSuccessEvent.emit(false);
  }

}
