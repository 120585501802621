export class UserDetailModel {
    email: string
    first_name: string
    id: number
    image: string
    is_sales_person: false
    job_title: string
    last_name: string
    middlename: string
    permission: [PermissionsModel]
    roles: string
    type: any
    username: string
    teams: []
}


export class LoginModel {
    message: string
    status: boolean
    token: string
    user: UserDetailModel
}

export class PermissionsModel{
    action: string
    content_type: string
    id: number
    name: string
    selected: boolean = false
}

export class UserModel{
    id: number
    user_name: string
    middlename: string
    type: string
    job_title: string
    photo_url: string
    provider: string
    auth_user: []
    group:[]
    permissions: []
}


export class UserResponseModel {
    count: number
    next: string
    previous: string
    results: [UserModel]
}