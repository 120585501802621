import { Injectable, inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AppCommon } from "../app-common"; 

@Injectable()
export class AuthGurdService {

  constructor(private router: Router){ 
  }

  canActivate(): boolean {
    const loggedObject: any = AppCommon.getloggedInObject();
    const token: any = loggedObject?.token;
    if (loggedObject && token){ 
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    } 
  }


}


const canActivateTeam: CanActivateFn = () => {
      return inject(AuthGurdService).canActivate();
    };

export default canActivateTeam