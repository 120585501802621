

  
<div class="modal fade" id="ImportEventData" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="ImportEventDataLabel" aria-hidden="true">
<div class="modal-dialog">
  <!-- <form [formGroup]="companyForm" (ngSubmit)="onSubmit()"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="ImportEventDataLabel">Import Event Data</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeClicked()"></button>
      </div>
      <div class="modal-body">
         
            <div>
                <div class="alert alert-warning d-flex align-items-center" role="alert">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    <div class="ms-2">
                      We accept CSV file upload only
                    </div>
                  </div>
            </div>
            <div>
              <p>Download sample file <a href="../../../assets/samples/upload_sample.csv" download class="text-decoration-none">participants_sample.csv</a></p>
            </div>
            <div>
                <div class="mb-3">
                    <label for="fileInput" class="form-label">Choose file:</label>
                    <input type="file" id="fileInput"  #myFileInput class="form-control"
                    (change)="onFileSelected($event)" accept=".csv">
                  </div>
                  
                  
            </div>
 
           


      </div>
      <div class="modal-footer">
        <button type="button" id="importModalCloseBtn" (click)="closeClicked()"
         class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="onFileUpload()">Upload</button> 
      </div>


    </div> 
  <!-- </form> -->
  </div>

</div>