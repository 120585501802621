import { Injectable } from '@angular/core';
import { BehaviorSubject, Observer } from 'rxjs';
import { LoaderComponent } from 'src/app/components/loader/loader.component';

@Injectable({ providedIn: 'root' })
export class LoaderService {
 
  loadingModel = new BehaviorSubject<boolean>(false);

  parentConponent: LoaderComponent;


  constructor(){
  }

  subToLoading(observer: Observer<boolean>){
    this.loadingModel.subscribe(observer)
  }

  showLoading() {
    this.loadingModel.next(true);
  }

  hideLoading() {
    this.loadingModel.next(false);
  }

 
}