import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-list-data',
  templateUrl: './empty-list-data.component.html',
  styleUrls: ['./empty-list-data.component.scss']
})
export class EmptyListDataComponent {

}
