export class CompanyResponseModel {
    count: number
    next: string
    previous: string
    results: [CompanyModel]
}

export class CompanyModel {
    created_at: string
    id: number
    linkedin_url: string
    name: string
    updated_at: string
    website: string
    participant_count: number
    thumbnail: string
}