import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppCommon } from 'src/api-providers/app-common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  isLoggedIn: boolean = false;


  constructor(
    private router: Router
  ){

  }


  logout(){
    this.isLoggedIn = false; 
    AppCommon.removeItemsOnLogOut();
    this.router.navigate(['/login']);
  }

}
