import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiServiceDelegate } from './api-service-delegate';
import { environment } from '../../environments/environment';
import { AppCommon } from '../app-common'; 

@Injectable()
export class ApiService {

    url = environment.api_url;

    constructor(private http: HttpClient) { }

    // this method is provided to client classes to call api
    callService(data: ApiServiceDelegate) {
        var newURL = this.url + data.Action;
        this.execute(data.Data, newURL, data.Method).subscribe(
        (result: any) => {
            let res = result.res;
            data.Success(res);
        },
        (error: any) => {
            if (typeof error['Errors'] !== 'undefined') {
            data.Error(error['Errors']);
            }
        }
        );
    }

    execute(apiData: any, url: string, method: String) {
      if (method == 'POST') {
        return this.http.post(url, apiData, this.getHeaderOptions()).pipe(map((res: any) => {return { res: res };})).pipe(catchError(this.errorHandler));
      } else if (method == 'DELETE') {
        return this.http.delete(url, this.getHeaderOptions()).pipe(map((res: any) => {return { res: res };})).pipe(catchError(this.errorHandler));
      } else if (method == 'PATCH') {
        return this.http.patch(url, apiData, this.getHeaderOptions()).pipe(map((res: any) => {return { res: res }; })).pipe(catchError(this.errorHandler));
      } else if (method == 'PUT') {
        return this.http.put(url, apiData, this.getHeaderOptions()).pipe(map((res: any) => {return { res: res }; })).pipe(catchError(this.errorHandler));
      } else {
        return this.http.get(url, this.getHeaderOptions()).pipe(map((res: any) => {return { res: res };})).pipe(catchError(this.errorHandler));
      }
    } 

    getHeaderOptions() {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');        
        const loggedObject: any= AppCommon.getloggedInObject();
        const token: any = loggedObject?.token;
        if (loggedObject && token){
          httpHeaders = httpHeaders.set("Authorization", "Bearer " + token);
        } 
        const httpOptions = {
          headers: httpHeaders
        };
        return httpOptions;
    }
 
    errorHandler(e: any) {
        return throwError({ Errors: e.error });
    }


    
     // to handle api of download a file
     downloadImage(url: string) { 
      var newURL = this.url + url;
      let httpHeaders = new HttpHeaders();
      httpHeaders.append("Accept", "application/octet-stream");
      const loggedObject: any= AppCommon.getloggedInObject();
      const token: any = loggedObject?.token;
      if (loggedObject && token){
        httpHeaders = httpHeaders.set("Authorization", "Bearer " + token);
      } 
      
      return this.http
        .get(newURL, {
          headers: httpHeaders,
          responseType: 'blob'
        })
        .pipe(catchError(this.errorHandler));
    }


     // to handle api of download a file
    downloadFile(url: string) { 
      var newURL = this.url + url;
      let httpHeaders = new HttpHeaders();
      httpHeaders.append("Accept", "application/zip");
      const loggedObject: any= AppCommon.getloggedInObject();
      const token: any = loggedObject?.token;
      if (loggedObject && token){
        httpHeaders = httpHeaders.set("Authorization", "Bearer " + token);
      } 
      
      return this.http
        .get(newURL, {
          headers: httpHeaders,
          responseType: 'arraybuffer'
        })
        .pipe(catchError(this.errorHandler));
    }


    downloadCSVFile(url: string){ 
      var newURL = this.url + url;
      let httpHeaders = new HttpHeaders();
      httpHeaders.append("Accept", "application/json");
      const loggedObject: any= AppCommon.getloggedInObject();
      const token: any = loggedObject?.token;
      if (loggedObject && token){
        httpHeaders = httpHeaders.set("Authorization", "Bearer " + token);
      } 
      var HTTPOptions = {
        headers: httpHeaders,
        observe: "response" as "body", // to display the full response & as 'body' for type cast
        responseType: "blob" as "json",
      };
      return this.http
        .get(newURL, HTTPOptions)
        .pipe(catchError(this.errorHandler));
        
    }


  uploadCSVFile(url: string, file: any) {
    var newURL = this.url + url;
    const uploadedFile = new FormData();
    uploadedFile.set( "file", new Blob([file], { type: "text/csv" }), file.name );
    let httpHeaders = new HttpHeaders();
    const loggedObject: any = AppCommon.getloggedInObject();
    const token: any = loggedObject?.token;
    if (loggedObject && token){
      httpHeaders = httpHeaders.set("Authorization", "Bearer " + token);
    } 
    httpHeaders.append("Accept", "application/x-www-form-urlencoded");
    const httpOptions = {
      headers: httpHeaders,
    };
    return this.http
      .post(newURL, uploadedFile, httpOptions)
      .pipe(
        map((res: any) => {
          return { res: res };
        })
      )
      .pipe(catchError(this.errorHandler));
  }
 
 
    


}