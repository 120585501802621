import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventModel } from 'src/api-providers/models/event.model';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent {

  @Input() event: EventModel;

  constructor(
    private router: Router
  ){

  }

  goToDetail(slug: string){
    this.router.navigate(['/admin/events/', slug]);
  }

}
