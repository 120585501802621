

  
<div class="modal fade" id="companyForm" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="companyFormLabel" aria-hidden="true">
<div class="modal-dialog">
  <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="companyFormLabel">{{companyModel ? companyModel.name:'Add Company'}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         
     
            <div class="form-group">
              <label for="name">Name *</label>
              <input type="text" class="form-control" id="name" formControlName="name"
              placeholder="Enter company name">              
              <div *ngIf="companyForm.get('name')!.invalid && (companyForm.get('name')!.dirty || companyForm.get('name')!.touched)" 
              class="text-fix text-danger">
                <div *ngIf="companyForm.get('name')!.errors?.['required']">Name is required.</div>
                <div *ngIf="companyForm.get('name')!.errors?.['minlength']">Name must be at least 2 characters long.</div>
              </div>

            </div>
          
            <div class="form-group mt-2">
              <label for="website">Website *</label>
              <input type="text" class="form-control" id="website" formControlName="website"
              placeholder="https://www.example.com">
              <div *ngIf="companyForm.get('website')!.invalid && (companyForm.get('website')!.dirty || companyForm.get('website')!.touched)" 
              class="text-fix text-danger">
                <div *ngIf="companyForm.get('website')!.errors?.['required']">Website is required.</div>
              </div>
            </div>
          
            <div class="form-group mt-2">
              <label for="linkedin_url">LinkedIn URL</label>
              <input type="text" class="form-control" id="linkedin_url"
              placeholder="https://www.linkedin.com/company/...."
               formControlName="linkedin_url">
            </div>
          
           


      </div>
      <div class="modal-footer">
        <button type="button" id="companyModalCloseBtn" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary mt-3 ms-auto">{{companyModel ? 'Update Company':'Add Company'}}</button> 
      </div>


    </div> 
  </form>
  </div>

</div>