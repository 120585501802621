

  
<div class="modal modal-xl fade" id="eventForm" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="eventFormLabel" aria-hidden="true">
<div class="modal-dialog">
  <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="eventFormLabel">{{eventModel ? eventModel.name:'Add Event'}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div class="row">



          <div class="col-6">


            <div class="form-group">
              <label for="name">Name *</label>
              <input type="text" class="form-control" id="name" formControlName="name"
              placeholder="Event Name">
              <div *ngIf="eventForm.get('name')!.invalid && (eventForm.get('name')!.dirty || eventForm.get('name')!.touched)" 
              class="text-fix text-danger">
                <div *ngIf="eventForm.get('name')!.errors?.['required']">Name is required.</div>
                <div *ngIf="eventForm.get('name')!.errors?.['minlength']">Name must be at least 4 characters long.</div>
              </div>
            </div> 


            <div class="form-group mt-3">
              <label for="event_agenda_url">Agenda URL *</label>
              <input type="url" class="form-control" id="event_agenda_url" formControlName="event_agenda_url"
              placeholder="https://www.commonsense.events/events/...">
              <div *ngIf="eventForm.get('event_agenda_url')!.invalid && (eventForm.get('event_agenda_url')!.dirty || eventForm.get('event_agenda_url')!.touched)" 
              class="text-fix text-danger">
                <div *ngIf="eventForm.get('event_agenda_url')!.errors?.['required']">Agenda URL is required.</div>
                <div *ngIf="eventForm.get('event_agenda_url')!.errors?.['minlength']">Agenda URL must be at least 10 characters long.</div>
              </div>
            </div>

            <div class="form-group mt-3">
              <label for="event_faqs_url">FAQs URL</label>
              <input type="url" class="form-control" id="event_faqs_url" formControlName="event_faqs_url"
              placeholder="https://www.commonsense.events/events/...">
            </div>
          

            <div class="form-group mt-3">
              <label for="event_format_url">Event Format URL</label>
              <input type="url" class="form-control" id="event_format_url" formControlName="event_format_url"
              placeholder="https://www.commonsense.events/events/...">
            </div>

            
            <div class="form-group mt-3">
              <label for="event_sponsors_url">Event Sponsors URL</label>
              <input type="url" class="form-control" id="event_sponsors_url" formControlName="event_sponsors_url"
              placeholder="https://www.commonsense.events/events/...">
            </div>

          </div>

          <div class="col-6">

            
            <div class="form-group">
              <label for="event_panel_sessions_url">Event Session URL</label>
              <input type="url" class="form-control" id="event_panel_sessions_url" formControlName="event_panel_sessions_url"
              placeholder="https://www.commonsense.events/events/...">
            </div>



            <div class="form-group mt-3">
              <label for="description">Description *</label>
              <textarea class="form-control" id="description" formControlName="description" rows="4"
              placeholder="Virtual and in-person thought leadership events with an emphasis on conversation, not presentation.."></textarea> 
              <div *ngIf="eventForm.get('description')!.invalid && (eventForm.get('description')!.dirty || eventForm.get('description')!.touched)" 
              class="text-fix text-danger">
                <div *ngIf="eventForm.get('description')!.errors?.['required']">Description is required.</div>
                <div *ngIf="eventForm.get('description')!.errors?.['minlength']">Description must be at least 20 characters long.</div>
              </div>
            </div>

 

            <label for="name" class="mt-4" >Sessions</label>
            <ng-select placeholder="Select sessions" formControlName="session" [multiple]="true">
              <ng-option *ngFor="let session of sessionsResponseModel.value.results" [value]="session.id">
                <span class="text-capitalize">{{session.name}}</span> ({{session.type_name}})</ng-option>
           </ng-select>

           <div class="mt-4">
              
            <div class="form-check" style="white-space: nowrap;">
              <input class="form-check-input" type="checkbox" id="no_registration_required" formControlName="no_registration_required">
              <label class="form-check-label" for="no_registration_required">
                Is registration required?
              </label>
            </div>

              <div class="text-muted text-fix mt-2">If left unchecked, the QR code that eliminates paper usage will direct participants to the success page.</div>
           
            </div>

          </div>

          


 
  
        </div>
         
     
          
        
          
            
          

          
          
           


      </div>
      <div class="modal-footer">
        <button type="button" id="eventModalCloseBtn" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary mt-3 ms-auto">{{eventModel ? 'Update Event':'Add Event'}}</button> 
      </div>


    </div> 
  </form>
  </div>

</div>