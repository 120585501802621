<div class="container-fluid  bg-secondary" style="height:100vh">
    <div class="row d-flex justify-content-center  h-100 py-5 w-100 mx-0">

        <div class="col-12 col-md-6 col-lg-4 shadow mt-auto mb-auto bg-remix text-center">

            <div class="mt-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="274.694" height="61.898" viewBox="0 0 274.694 61.898">
                    <g id="Group_4" data-name="Group 4" transform="translate(-126.694 -378.557)">
                      <g id="Group_2" data-name="Group 2" transform="translate(127.62 378.557)">
                        <path id="Polygon_1" data-name="Polygon 1" d="M9.768,0l9.768,22.107H0Z" transform="matrix(0.899, -0.438, 0.438, 0.899, 0.823, 17.405)" fill="#fff"></path>
                        <path id="Polygon_2" data-name="Polygon 2" d="M7.955,0l7.955,34.445H0Z" transform="translate(40.542) rotate(45)" fill="#fff"></path>
                        <path id="Polygon_3" data-name="Polygon 3" d="M10.282,0,20.564,27.762H0Z" transform="translate(56.632 40.205) rotate(135)" fill="#fff"></path>
                        <path id="Polygon_4" data-name="Polygon 4" d="M8.805,0,17.61,30.457H0Z" transform="translate(12.452 61.898) rotate(-135)" fill="#fff"></path>
                      </g>
                      <g id="Group_1" data-name="Group 1" transform="translate(126.694 378.791)">
                        <path id="Icon_awesome-paper-plane" data-name="Icon awesome-paper-plane" d="M31.8.214.835,18.078a1.6,1.6,0,0,0,.147,2.886l7.1,2.98L27.277,7.028a.4.4,0,0,1,.575.554L11.758,27.19v5.378A1.6,1.6,0,0,0,14.6,33.624L18.84,28.46l8.324,3.487a1.608,1.608,0,0,0,2.2-1.216L34.178,1.87A1.6,1.6,0,0,0,31.8.214Z" transform="translate(17.636 0.002)" fill="#0dcaf0"></path>
                        <path id="Icon_awesome-paper-plane-2" data-name="Icon awesome-paper-plane" d="M31.8.216.834,18.08a1.6,1.6,0,0,0,.147,2.886l7.1,2.98L27.277,7.03a.4.4,0,0,1,.575.554L11.757,27.192V32.57A1.6,1.6,0,0,0,14.6,33.626l4.242-5.164,8.324,3.487a1.608,1.608,0,0,0,2.2-1.216l4.81-28.861A1.6,1.6,0,0,0,31.8.216Z" transform="translate(38.337 59.918) rotate(-180)" fill="#0dcaf0"></path>
                        <path id="Icon_awesome-paper-plane-3" data-name="Icon awesome-paper-plane" d="M17.57.119.461,9.99a.886.886,0,0,0,.081,1.595l3.924,1.646L15.071,3.884a.221.221,0,0,1,.317.306L6.5,15.025V18a.886.886,0,0,0,1.569.583l2.344-2.853,4.6,1.927a.888.888,0,0,0,1.218-.672L18.884,1.035A.886.886,0,0,0,17.57.119Z" transform="translate(0 25.404) rotate(-60)" fill="#0dcaf0"></path>
                        <path id="Icon_awesome-paper-plane-4" data-name="Icon awesome-paper-plane" d="M20.48.139.537,11.644A1.033,1.033,0,0,0,.632,13.5l4.574,1.919L17.567,4.528a.258.258,0,0,1,.37.357L7.572,17.513v3.464a1.032,1.032,0,0,0,1.829.68l2.732-3.326,5.361,2.246a1.036,1.036,0,0,0,1.42-.783l3.1-18.588A1.033,1.033,0,0,0,20.48.139Z" transform="matrix(-0.259, 0.966, -0.966, -0.259, 58.079, 29.831)" fill="#0dcaf0"></path>
                        <text id="Paperless" transform="translate(56.694 45.147)" fill="#0dcaf0" font-size="49" font-family="ArialMT, Arial"><tspan x="0" y="0">Paperless</tspan></text>
                      </g>
                    </g>
                  </svg>
            </div>

            <div>
             <p class="mt-2 text-wheat" > Less paper, more conversations</p>
            </div>

        

            <hr class="mt-3">
            <h3 class="mt-2 text-wheat">Login</h3>
            <div class="google-btn">
            <asl-google-signin-button type='standard' size='medium' text="signin_with" theme="filled_blue">
            </asl-google-signin-button>
            </div>
  
            <p class="copy-rights text-wheat">© {{currentYear}} BuyerForesight</p>
            
        </div>

    </div>
</div>