import { Injectable } from '@angular/core';
import { BehaviorSubject, Observer } from 'rxjs';
import { AlertModel } from '../models/app.model'; 
import { AlertComponent } from 'src/app/components/alert/alert.component';

@Injectable({ providedIn: 'root' })
export class AlertService {

  private alerts: AlertModel[] = [];

  subsAlertsModel = new BehaviorSubject<AlertModel[]>([new AlertModel]);

  parentConponent: AlertComponent;


  constructor(){
  }

  subToAlerts(observer: Observer<AlertModel[]>){
    this.subsAlertsModel.subscribe(observer)
  }

  addAlert(message: string, type: string) {
    var payload = {message: message, type: type}
    this.alerts.push(payload);
    setTimeout(() => {
      this.removeAlert(payload);
    }, 3000);
  }

  removeAlert(alert: AlertModel) { 
    const index = this.alerts.indexOf(alert);
    if (index !== -1) {
      this.alerts.splice(index, 1);
    }
  }

  getAlerts() {
    this.subsAlertsModel.next(this.alerts);
  }
}