import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from 'src/api-providers/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  providers: []
})
export class LoaderComponent { 

  isLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private loaderService: LoaderService
  ){
    this.loaderService.subToLoading(this.isLoading);
  }

}
