import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observer } from "rxjs";
import { ApiService } from "../../api-providers/services/api-service";
import { ApiServiceDelegate } from "../../api-providers/services/api-service-delegate";
import { ApiCollection } from "../../api-providers/services/api-collection";
import { AppCommon } from "../../api-providers/app-common";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { LoginModel } from "../models/user.model"; 
import { LoginComponent } from "src/app/login/login.component";
import { LoaderService } from "../services/loader.service";


@Injectable({
    providedIn: "root",
})

export class LoginViewModel {

    parentLoginComponent: LoginComponent;
 

    loginModel = new BehaviorSubject<LoginModel>(new LoginModel());

    constructor(
        private apiService: ApiService,
        private authService: SocialAuthService,
        private loaderService: LoaderService
    ) {

    }

    subscribeLogin(
      observer: Observer<LoginModel>
    ){
      this.loginModel.subscribe(observer)
    }

    signInWithGoogle(){
        this.authService
          .signIn(GoogleLoginProvider.PROVIDER_ID)
          .then((x) => this.authenticate(x.idToken));
    }

 


    authenticate(token: string){
        this.loaderService.showLoading();
        let d: ApiServiceDelegate = {
            Data: { token: token },
            Method: "POST",
            Action: ApiCollection.Social_Login,
            Success: (e: any) => {
                if (e.status === true) {
                    AppCommon.setLoggedInObject(e);
                    this.loginModel.next(e);
                    this.parentLoginComponent.loginSuccess();
                    
                } else {
                    console.log("login error");
                }
                this.loaderService.hideLoading();
            return e;
            },
            Error: (e: String) => {
                this.loaderService.hideLoading();
                    return e;
            },
        };
        this.apiService.callService(d);
    }



}
