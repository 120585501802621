import { Component } from '@angular/core';
import { AppCommon } from 'src/api-providers/app-common';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
  providers: [AppCommon]
})
export class Page404Component {

  commonsense_events_url:string = "https://www.commonsense.events/events/" + AppCommon.getUTMParam();
  constructor() { 

    
  }

}
