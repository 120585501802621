<nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
    <ol class="breadcrumb">  
      <li class="breadcrumb-item {{item.disabled ? '':'active'}}" *ngFor="let item of breadcrumbs">
        <a *ngIf="item.disabled"><strong>
          {{item.name.length > 30 ? (item.name|slice:0:30) + '...':item.name}}
        </strong></a>
        <a *ngIf="!item.disabled" href="{{item.link}}"><strong>
          {{item.name.length > 30 ? (item.name|slice:0:30) + '...':item.name}}</strong></a>
      </li>  
    </ol>
  </nav>