import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent {

  searchQuery: string = '';

  typingTimer:any
  doneTypingInterval:number = 600

  @Output() searchModelChange = new EventEmitter<string>();

  constructor(){}

  ngOnInit():void {
    
  }

   //on keyup, start the countdown
   inputkeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.filterData.bind(this), this.doneTypingInterval);
  };

  //on keydown, clear the countdown 
  inputKeyDown() {
    clearTimeout(this.typingTimer);
  };

 

  filterData(){
    this.searchModelChange.emit(this.searchQuery)
  }
}
