 





    
 
 
    <div class="d-flex w-100 h-100 p-3 mx-auto flex-column wrapper">
 
     
        <div class="container mt-5 col-7">
            <div class=" text-center mt-3 px-3 col-lg-12 justify-content-center align-items-center">

              
                                 
                <h1 class="text-left text-white"> 
                    Sorry!
                </h1>
                
            </div>


            <div class="row ">
                    <div class="col-lg-7 mx-auto mt-2">
                        <div class="card mt-2 mx-auto p-4 formbg">
                            <div class="card-body">
                    
                            <div class = "container"> 

                            <div class="controls">


                            

                                <div class="row"> 
                        
                                    <p class="text-white mt-2 text-center" style="font-size: 18px;">

                                            Page not found 
                                    </p>    


                                    <p class="text-white mt-2 text-center" style="font-size: 18px;">
                                        <code>(Error: 404 - please provide a valid link)</code>
                                </p>    

                        
                                
                                        
                                    <div class="col-md-12">
                                        <div class="form-groupyes">
                                            <a href="{{commonsense_events_url}}" 
                                            class="btn btn-success btn-send  pt-2 btn-block">
                                                Explore Our Events
                                            </a>
                                        </div>
                                    </div>
                                    
                                
                                </div>
                                
                        
                            
                                
                            
                                <div class="row">
                                    <div class="col-md-12"> 
                                        </div>  

                                    <div class="col-md-12 text-center">
                                        
                                    
                                    
                                </div>
                        
                                </div>  


                        </div>
            
                        </div>
                            </div>


                        </div>
            

                    </div>
            

                    <div class="row">
                                    <div class="col-md-12  text-center">
                                        <div class="form-group">
                                            <img src="../../../assets/images/logo.png">
                                        </div>
                                    </div>
                    </div>
                
            </div>
            
        </div>

</div>  









