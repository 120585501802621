import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppCommon } from 'src/api-providers/app-common';
import { ParticipantEventDetailModel } from 'src/api-providers/models/participant.model';
import { ApiService } from 'src/api-providers/services/api-service';
import { ScanViewModel } from 'src/api-providers/view-models/scan.view.model';

@Component({
  selector: 'app-customer-scan',
  templateUrl: './customer-scan.component.html',
  styleUrls: ['./customer-scan.component.scss'],
  providers: [ScanViewModel, ApiService, AppCommon]
})
export class CustomerScanComponent implements OnInit { 

  participantUniqueCode: string = '';
  isLoading: boolean = false;
  participantDetailModel = new BehaviorSubject<ParticipantEventDetailModel>(new ParticipantEventDetailModel());
  utm_params:string = AppCommon.getUTMParam();
  
  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private vm: ScanViewModel
  ) {
  }


  ngOnInit(): void {
    this.vm.parentCustomerScanComponent = this;
    this.participantUniqueCode = this.route.snapshot.paramMap.get('slug') || '';
    if(this.participantUniqueCode.length > 0){
      this.getData();
    } else {
      this.sendUserToPage404();
    }

  }

  sendUserToPage404(){
    this.router.navigate(['/page-not-found']);
  }

  getData(){
    this.vm.subscribeToViewModel(this.participantDetailModel);
    this.vm.getParticipantDetail(this.participantUniqueCode);
  }

  receivedParticipant(){

  }

  participantNotFound(){
    this.sendUserToPage404();
  }

}
