import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observer } from "rxjs";
import { ApiService } from "../services/api-service";
import { ApiServiceDelegate } from "../services/api-service-delegate";
import { ApiCollection } from "../services/api-collection"; 
import { CompaniesComponent } from "src/app/admin/companies/companies.component";
import { CompanyModel, CompanyResponseModel } from "../models/company.model";
import { ModalCompanyFormComponent } from "src/app/components/modal-company-form/modal-company-form.component";
import { AlertService } from "../services/alert.service";
import { ModalDeleteComponent } from "src/app/components/modal-delete/modal-delete.component";
import { LoaderService } from "../services/loader.service";

@Injectable({
    providedIn: "root",
})

export class CompanyViewModel {

   parentCompaniesComponent: CompaniesComponent;
   parentModalCompanyFormComponent: ModalCompanyFormComponent;
   parentModalDeleteComponent: ModalDeleteComponent;

   companiesResponseModel = new BehaviorSubject<CompanyResponseModel>(new CompanyResponseModel());

    constructor(private apiService: ApiService, private alertService: AlertService, private loaderService: LoaderService ) {
      
    }

    subscribeToViewModel(
        observer: Observer<CompanyResponseModel>
    ){
        this.companiesResponseModel.subscribe(observer);
    } 

    deleteCompany(id: string){  
      this.loaderService.showLoading();
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "DELETE",
        Action: ApiCollection.Company + '/' + id,
        Success: (e: any) => {
          this.parentModalDeleteComponent.deleteSuccess();
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => { 
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    createOrUpdateCompany(model: CompanyModel){
      this.loaderService.showLoading();
      var url = ApiCollection.Company;
      if(model.id){
        url += "/" + model.id
      }
      let d: ApiServiceDelegate = {
        Data: model,
        Method: model.id ? 'PATCH':'POST',
        Action: url,
        Success: (e: any) => {
          this.parentModalCompanyFormComponent.updatedCompanyMedel(e);
          this.alertService.addAlert(model.id ? 'Company updated!':'Company created!', 'success');
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => { 
          this.alertService.addAlert(model.id ? JSON.stringify(e).replaceAll('{',' ').replaceAll('}',' ').replaceAll('[',' ').replaceAll(']',' ') :
           JSON.stringify(e).replaceAll('{',' ').replaceAll('}',' ').replaceAll('[',' ').replaceAll(']',' '), 'danger');
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    getCompanies(name: string, page_index: number , page_size:number){
      this.loaderService.showLoading();
      var url = ApiCollection.Company + "?page="+page_index+"&page_size="+page_size;
      if(name.length > 0){
        url += "&name=" + encodeURIComponent(name)
      }
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: url,
        Success: (e: any) => {
          this.companiesResponseModel.next(e); 
          this.parentCompaniesComponent.companiesReceived();
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => { 
          this.loaderService.hideLoading();
          return e;

        },
      };
      this.apiService.callService(d);
    }

 

 


}
