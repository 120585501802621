<div class="fixed-bottom">
    <div class="dropup position-absolute bottom-0 end-0 m-5 ">
        <div class="alert alert-{{alert.type}} alert-dismissible fade show shadow-sm" role="alert" *ngFor="let alert of subsAlertsModel.value">
            <!-- <strong class="text-capitalize">{{alert.type}}!</strong>  -->
            {{alert.message}}
            <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closeAlert(alert)" aria-label="Close"></button>
        </div>
    </div>
</div>

<script type="application/javascript">  



</script>