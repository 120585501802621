import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { EventModel } from 'src/api-providers/models/event.model';
import { SessionResponseModel } from 'src/api-providers/models/session.model';
import { EventsViewModel } from 'src/api-providers/view-models/events.view.model';

@Component({
  selector: 'app-model-event-form',
  templateUrl: './model-event-form.component.html',
  styleUrls: ['./model-event-form.component.scss'],
})
export class ModelEventFormComponent { 
  
  eventModel!: EventModel; 
  eventForm: FormGroup;
  @Output() formSuccessEvent = new EventEmitter<any>();
  sessionsResponseModel = new BehaviorSubject<SessionResponseModel>(new SessionResponseModel());
  
  static StaticCall: any;

  constructor(
    private eventVM: EventsViewModel
  ) {
    ModelEventFormComponent.StaticCall = this;
    this.initFormFields();
  }

  ngOnInit():void {
    this.eventVM.parentModelEventFormComponent = this;
    this.eventVM.subscribeToSessionResponseModel(this.sessionsResponseModel);
    this.eventVM.getSessions();
  }



  updateCompanyFields(event: EventModel){
    this.eventModel = event;
    this.eventForm.setValue({ 
      name: event.name,
      description: event.description,
      event_agenda_url: event.event_agenda_url,
      event_faqs_url: event.event_faqs_url,
      event_format_url: event.event_format_url,
      event_panel_sessions_url: event.event_panel_sessions_url,
      event_sponsors_url: event.event_sponsors_url,
      no_registration_required: event.no_registration_required, 
      session: event.session
    })
  }

  initFormFields(){
    this.eventForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      description: new FormControl('', [Validators.required, Validators.minLength(20)]),
      event_agenda_url: new FormControl('', [Validators.required, Validators.minLength(10)]),
      event_faqs_url: new FormControl('', []),
      event_format_url: new FormControl('', []),
      event_panel_sessions_url: new FormControl('', []),
      event_sponsors_url: new FormControl('', []),
      no_registration_required: new FormControl(false, []),
      session: new FormControl('', []),
    }); 
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.eventForm.invalid) {
      this.validateAllFormFields(this.eventForm);
    } else {
      var newModel = new EventModel();
      newModel = this.eventForm.value
      if(this.eventModel?.slug){
        newModel.slug = this.eventModel.slug;  
      }        
      this.eventVM.createOrUpdateEvent(newModel);
    }
  }

  updatedEventMedel(event: EventModel){
    document.getElementById("eventModalCloseBtn")?.click();
    this.formSuccessEvent.emit(event);
  }



}
