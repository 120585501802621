<header class="p-3 text-bg-dark header-bg" >
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" class="navbar-brand">
          <img src="../../../assets/images/logo-small.png" alt="Common Sense Conferences"
           class="cslogo" height="50px">
        </a>

        <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ms-2">
          <li class="nav-item"><a routerLink="/admin/events" [routerLinkActive]="['btn-info']"
            style="font-size: 1rem;  border-color: #0F7395!important;" 
            class="me-auto px-2 text-white btn btn-outline-info me-2 btn-sm
            "><i class="fa-solid fa-calendar-days"></i> Events</a></li>


          <li class="nav-item"><a routerLink="/admin/sessions" [routerLinkActive]="['btn-info']"
            style="font-size: 1rem;  border-color: #0F7395!important;" 
            class="px-2 text-white btn btn-outline-info ms-2 btn-sm
            
            "><i class="fa-solid fa-timeline"></i> Sessions</a></li>

           
             <li class="nav-item"><a routerLink="/admin/companies" [routerLinkActive]="['btn-info']"
            style="font-size: 1rem;  border-color: #0F7395!important;" 
            class="px-2 text-white btn btn-outline-info ms-2 btn-sm
            
            "><i class="fa-regular fa-building"></i> Companies</a></li>

            <li class="nav-item"><a routerLink="/admin/participants" [routerLinkActive]="['btn-info']"
              style="font-size: 1rem;  border-color: #0F7395!important;" 
              class="px-2 text-white btn btn-outline-info ms-2 btn-sm
              
              "><i class="fa-solid fa-users-viewfinder"></i> Participants</a></li>
  
         
        </ul>


 

      

        <div class="text-end">
        <button type="button" style="font-size: 1rem;  border-color: #0F7395!important;" 
        class="btn btn-outline-light  btn-sm" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</button>
        </div>
      </div>
    </div>
</header>
 