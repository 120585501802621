import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observer } from "rxjs";
import { ApiService } from "../services/api-service";
import { ApiServiceDelegate } from "../services/api-service-delegate";
import { ApiCollection } from "../services/api-collection";
import { AppCommon } from "../app-common";
import { CustomerScanComponent } from "src/app/pages/customer-scan/customer-scan.component";
import { ParticipantEventDetailModel } from "../models/participant.model";
import { LoaderService } from "../services/loader.service";

@Injectable({
    providedIn: "root",
})

export class ScanViewModel {

   parentCustomerScanComponent: CustomerScanComponent;

   participantDetailModel = new BehaviorSubject<ParticipantEventDetailModel>(new ParticipantEventDetailModel());

    constructor(private apiService: ApiService, private loaderService: LoaderService) {
      
    }

    subscribeToViewModel(
        observer: Observer<ParticipantEventDetailModel>
    ){
        this.participantDetailModel.subscribe(observer);
    } 

    getParticipantDetail(slug: string){
      this.loaderService.showLoading();
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: ApiCollection.Participant_Event_Detail_Retrieve + '/' + slug,
        Success: (e: any) => {
          this.participantDetailModel.next(e);
          this.parentCustomerScanComponent.receivedParticipant();
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => {
          this.parentCustomerScanComponent.participantNotFound();
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

 

 


}
