import { EventModel } from "./event.model";
import { ParticipantModel } from "./participant.model"

export class SessionModel {

    public constructor(init?: Partial<SessionModel>) {
        Object.assign(this, init);
    }
    

    description: string
    event: number
    name: string
    id: number
    type: string
    type_name: string
    participant_details: [ParticipantModel]
    event_list: EventModel[]

}

export class SessionTypeResponseModel {
    count: number
    next: string
    previous: string
    results: [SessionTypeModel]
}

export class SessionTypeModel {
    name: string
    id: string
}

export class SessionResponseModel {
    count: number
    next: string
    previous: string
    results: [SessionModel]
}