import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SessionModel, SessionTypeResponseModel } from 'src/api-providers/models/session.model';
import { SessionsViewModel } from 'src/api-providers/view-models/sessions.view.model';

@Component({
  selector: 'app-model-session-form',
  templateUrl: './model-session-form.component.html',
  styleUrls: ['./model-session-form.component.scss']
})
export class ModelSessionFormComponent {

  
  sessionModel!: SessionModel; 
  sessionForm: FormGroup;
  @Output() formSuccessEvent = new EventEmitter<any>();
  sessionTypes = new BehaviorSubject<SessionTypeResponseModel>(new SessionTypeResponseModel());
  
  static StaticCall: any;

  constructor(
    private sessionVM: SessionsViewModel
  ) {
    ModelSessionFormComponent.StaticCall = this;
    this.initFormFields();
  }

  ngOnInit():void {
    this.sessionVM.parentModelSessionFormComponent = this;
    this.sessionVM.subscribeToSessionTypes(this.sessionTypes);
    this.sessionVM.getSessionTypes();
  }



  updateCompanyFields(session: SessionModel){
    this.sessionModel = session;
    this.sessionForm.setValue({ 
      name: session.name,
      description: session.description,
      type: session.type
    })
  }

  initFormFields(){
    this.sessionForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      description: new FormControl('', [Validators.required, Validators.minLength(10)]),
      type: new FormControl(undefined, [Validators.required])
    }); 
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.sessionForm.invalid) {
      this.validateAllFormFields(this.sessionForm);
    } else {
      var newModel = new SessionModel();
      newModel = this.sessionForm.value
      if(this.sessionModel?.id){
        newModel.id = this.sessionModel.id;  
      }        
      this.sessionVM.createOrUpdateSession(newModel);
    }
  }

  updatedSessionMedel(session: SessionModel){
    document.getElementById("sessionModalCloseBtn")?.click();
    this.formSuccessEvent.emit(session);
  }


}
