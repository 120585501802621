import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertModel } from 'src/api-providers/models/app.model';
import { AlertService } from 'src/api-providers/services/alert.service';

 

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  subsAlertsModel = new BehaviorSubject<AlertModel[]>([new AlertModel]); 

  constructor(
    private alertService: AlertService
  ){}


  ngOnInit(){
    this.alertService.parentConponent = this;
    this.alertService.subToAlerts(this.subsAlertsModel);
    this.alertService.getAlerts();
  }

  closeAlert(alert: AlertModel){
    this.alertService.removeAlert(alert);
    this.alertService.getAlerts();
  }



}
