import { CompanyModel } from "./company.model";
import { EventModel } from "./event.model";
import { SessionModel } from "./session.model"
 

export class PhoneCountryCodeResponseModel {
    count: number
    next: string
    previous: string
    results: [PhoneCountryCodeModel]
}
 
export class PhoneCountryCodeModel {
    code: string
    country: string
    id: number
 }

export class ParticipantResponseModel {
    count: number
    next: string
    previous: string
    results: [ParticipantModel]
}

export class PhoneInterface {
    country_code: number
    country_code_value: string
    country_name: string
    phone: string
  }
  
 

export class ParticipantModel {
    id: number
    first_name: string
    last_name: string
    title: string
    phone: any
    slug: string
    email: string
    linkedin_url: string
    company: number 
    company_details: CompanyModel
    tagged_event_details: EventModel[]
    event: number[]
}

export class ParticipantEventDetailResponseModel {
    count: number
    next: string
    previous: string
    results: [ParticipantEventDetailModel]
}

export class ParticipantEventDetailModel {
    id: number
    slug: string
    participant: number
    event: number
    sessions: []
    attendance: boolean 
    participant_details: ParticipantModel
    event_details: EventModel
    session_details: [SessionModel]
}

