import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { ApiService } from "../../api-providers/services/api-service";
import { ApiServiceDelegate } from "../../api-providers/services/api-service-delegate";
import { ApiCollection } from "../../api-providers/services/api-collection"; 
import { EventModel, EventResponseModel } from "../models/event.model";
import { EventsComponent } from "src/app/admin/events/events.component";
import { ModelEventFormComponent } from "src/app/components/model-event-form/model-event-form.component";
import { EventDetailComponent } from "src/app/admin/event-detail/event-detail.component";
import { ParticipantEventDetailResponseModel, ParticipantModel } from "../models/participant.model";
import { HttpResponse } from "@angular/common/http";
import { AlertService } from "../services/alert.service";
import { SessionResponseModel } from "../models/session.model";
import { ModalDeleteComponent } from "src/app/components/modal-delete/modal-delete.component";
import { ModalImportEventDataComponent } from "src/app/components/modal-import-event-data/modal-import-event-data.component";
import { LoaderService } from "../services/loader.service";
import { ModalAddToEventComponent } from "src/app/components/modal-add-to-event/modal-add-to-event.component";

@Injectable({
    providedIn: "root",
})

export class EventsViewModel {

    parentEventsComponent: EventsComponent;
    parentModelEventFormComponent: ModelEventFormComponent;
    parentEventDetailComponent: EventDetailComponent;
    parentModalDeleteComponent: ModalDeleteComponent;
    parentModalImportEventDataComponent: ModalImportEventDataComponent;
    parentModalAddToEventComponent: ModalAddToEventComponent

    eventsResponseModel = new BehaviorSubject<EventResponseModel>(new EventResponseModel()); 

    
    participantsResponseModel = new BehaviorSubject<ParticipantEventDetailResponseModel>(
      new ParticipantEventDetailResponseModel()
    );
  
    eventDetailsResponseModel = new BehaviorSubject<EventModel>(
      new EventModel()
    );

    sessionsResponseModel = new BehaviorSubject<SessionResponseModel>(new SessionResponseModel());

    constructor(
      private apiService: ApiService,
      private alertService: AlertService,
      private loaderService: LoaderService
       ) {
      
    }

    subscribeToParticipantsResponseModel(
      observer: Observer<ParticipantEventDetailResponseModel>
    ){
      this.participantsResponseModel.subscribe(observer);
    }

    subscribeToSessionResponseModel(
      observer: Observer<SessionResponseModel>
    ){
      this.sessionsResponseModel.subscribe(observer);
    }

    subscribeToEventDetailModel(
      observer: Observer<EventModel>
    ){
      this.eventDetailsResponseModel.subscribe(observer);
    }

    subscribeToEventsResponse(
        observer: Observer<EventResponseModel>
    ){
        this.eventsResponseModel.subscribe(observer);
    }

    getSessions(){
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: ApiCollection.Session + '?page=1&page_size=100',
        Success: (e: any) => {
          this.sessionsResponseModel.next(e);
          return e; 
        },
        Error: (e: String) => { 
          return e;
        },
      };
      this.apiService.callService(d);
    }

    getEventById(slug: string){ 
      var url = ApiCollection.Event + '/' + slug;
   
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: url,
        Success: (e: any) => {
          this.eventDetailsResponseModel.next(e); 
          this.parentEventDetailComponent.eventReceived()
          return e; 
        },
        Error: (e: String) => { 
          return e;
        },
      };
      this.apiService.callService(d);
    }

    removeParticipantDetailFromEvent(id: string){
      this.loaderService.showLoading();
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "DELETE",
        Action: ApiCollection.Participant_Event_Detail + '/' + id,
        Success: (e: any) => {
          this.parentModalDeleteComponent.deleteSuccess();
          this.loaderService.hideLoading();
          this.alertService.addAlert('Participant removed from the event', 'success');
          return e;
        },
        Error: (e: String) => { 
          this.loaderService.hideLoading();
          this.alertService.addAlert('Unable to remove participant from the event', 'danger');
          return e;
        },
      };
      this.apiService.callService(d);
    }

    deleteEvent(slug: string){
      this.loaderService.showLoading();
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "DELETE",
        Action: ApiCollection.Event + '/' + slug,
        Success: (e: any) => {
          this.parentModalDeleteComponent.deleteSuccess();
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => { 
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    getEvents(name: string, page_index:number, page_size:number, caller: string){ 
      this.loaderService.showLoading();
      var url = ApiCollection.Event + "?page="+page_index+"&page_size="+page_size;
      if(name.length > 0){
        url += "&name=" + encodeURIComponent(name);;
      }
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: url,
        Success: (e: any) => {
          this.eventsResponseModel.next(e); 
          if(caller === 'events'){
            this.parentEventsComponent.eventReceived(e);
          }
          if(caller === 'add-to-events'){
            this.parentModalAddToEventComponent.eventReceived();
          }

          this.loaderService.hideLoading();
          return e; 
        },
        Error: (e: String) => { 
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    createOrUpdateEvent(event: EventModel){
      this.loaderService.showLoading();
      var url = ApiCollection.Event;
      if(event.slug){
        url += "/" + event.slug
      }
      let d: ApiServiceDelegate = {
        Data: event,
        Method: event.slug ? 'PATCH':'POST',
        Action: url,
        Success: (e: any) => {
          this.parentModelEventFormComponent.updatedEventMedel(e);
          this.alertService.addAlert(event.slug ? 'Event updated!':'Event created!', 'success');
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => { 
          this.alertService.addAlert(event.slug ? "Unable to update event":'Event is not created!', 'danger');
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }


    getParticipants(eventId: string, name: string,pageNo:number,page_size:number, attendance: null | boolean, sessions: number[]){ 
      var url = ApiCollection.Participant_Event_Detail + "?page="+pageNo+"&page_size="+page_size + "&e=" + eventId;
      if(name.length > 0){
        url += "&participant_name=" + encodeURIComponent(name);
      }
      if(attendance != null){
        url += "&attendance=" + attendance;
      }
      if(sessions.length > 0){
        url += "&session=" + sessions.join(',');
      }
      this.loaderService.showLoading();
      let d: ApiServiceDelegate = {
        
        Data: {},
        Method: "GET",
        Action: url,
        Success: (e: any) => {
          e.results.forEach((element: any) => {
            if(element.participant_details.phone != null){
              element.participant_details.phone =  JSON.parse(element.participant_details.phone)
            } else {
              element.participant_details.phone = []
            }
          });
          this.participantsResponseModel.next(e); 
          this.parentEventDetailComponent.participantsReceived(e);
          this.loaderService.hideLoading();
          return e; 
        },
        Error: (e: String) => { 
          this.parentEventDetailComponent.participantsReceived(e);
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }


    downloadQR(slug: string){
      this.loaderService.showLoading();
      this.apiService.downloadFile(ApiCollection.DowloadEventQR + '?slug=' + slug).subscribe((e: any) => {
        const blob = new Blob([e], {
          type: 'application/zip'
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        this.loaderService.hideLoading();
      },
        (error: any) => {
          console.log(error)
          this.loaderService.hideLoading();
        }
      );
    }
 
    exportEventData(slug: string, name: string){
      this.loaderService.showLoading();
      this.apiService.downloadCSVFile(ApiCollection.ExportEventData +'/'+ slug).subscribe((e: any) => {
        const blob: any = new Blob([e.body], { type: 'text/csv' });
        const filename = name
        let link = document.createElement("a");
        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename+".csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); 
        }
        this.loaderService.hideLoading();
      },
        (error: any) => {
          console.log(error);
          this.loaderService.hideLoading();
        }
      );
    }


    importEventData(file: File, eventId: string){
      this.loaderService.showLoading();
      this.apiService.uploadCSVFile((ApiCollection.ImportEventData + "?event=" + eventId), file).subscribe((response)=> {
        this.parentModalImportEventDataComponent.importComplete();
        this.loaderService.hideLoading();
        this.alertService.addAlert('Participants imported', 'success');
      },
      (error: any) => {
        console.log(error);
        this.loaderService.hideLoading();
        this.alertService.addAlert('Unable to import participants', 'success');
      }
      )
    }


    addParticipantToEvent(participantId: number, eventId: number){
      var url = ApiCollection.Participant_Event_Detail;
      let d: ApiServiceDelegate = {
        Data: {
          participant: participantId,
          event: eventId
        },
        Method: 'POST',
        Action: url,
        Success: (e: any) => {
          this.parentModalAddToEventComponent.addedToEvent();
          this.alertService.addAlert('Participant added to event', 'success');
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: any) => { 
          this.parentModalAddToEventComponent.closeModalOnError();      
          this.alertService.addAlert('Participant is already in the event', 'danger');  
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

}
