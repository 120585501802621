 

export class AppCommon {
    static getUTMParam(): string {
        return "?utm_source=Paperless&utm_medium=registration&utm_campaign=event"
    }
     

    constructor() { }


    static setLoggedInObject(e: any){
        var data = JSON.stringify(e)
        localStorage.setItem("loggedInUser", data);
    }

    static getloggedInObject(){ 
        var data = localStorage.getItem("loggedInUser") || undefined;
        if(data != undefined){
            data= JSON.parse(data)
        }
        return data; 
    }

     
    static setGoogleUserObject(e: any){
        var data = JSON.stringify(e)
        localStorage.setItem("googleUserProfile", data);
    }

    static getGoogleUserObject() {
        var data = localStorage.getItem("googleUserProfile") || undefined;
        if(data != undefined){
            data= JSON.parse(data)
        }
        return data;
    }
    
    static removeItemsOnLogOut(){
        localStorage.removeItem("googleUserProfile");
        localStorage.removeItem("loggedInUser");
    }


    
    
}