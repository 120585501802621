
<app-loader *ngIf="isLoading"></app-loader>
 
    <div class="d-flex w-100 h-100 p-3 mx-auto flex-column wrapper">
 
     
        <div class="container mt-5 col-md-12 col-lg-12" *ngIf="participantDetailModel.value.event_details">
            <div class=" text-center mt-1 px-3 col-lg-12 justify-content-center align-items-center">
                <h3 class="text-center text-white">
                    Welcome to the
                </h3>
                <h3 class="text-center text-white">
                    {{participantDetailModel.value.event_details.name}}
                </h3>
                <h5 class="text-center text-white mt-3 mb-3" >
                    {{participantDetailModel.value.event_details.description}}
                </h5>
                    
                
            </div>


            <div class="row ">
                    <div class="col-lg-7 col-md-8 mx-auto mt-2">
                        <div class="card mt-2 mx-auto p-1 formbg">
                            <div class="card-body">
                    
                            <div class = "container"> 

                            <div class="controls">


                            

                                <div class="row"> 
                                
                                    
                                    <div class="col-md-12" *ngIf="participantDetailModel.value?.event_details?.event_agenda_url">
                                        <div class="form-groupyes">
                                            <a target="_blank" href="{{participantDetailModel.value.event_details.event_agenda_url + utm_params}}" 
                                            class="btn btn-success btn-send  pt-2 btn-block">
                                            AGENDA
                                            </a>
                                        </div>
                                    </div>
                                    
                                
                                    <div class="col-md-12" *ngIf="participantDetailModel.value?.event_details?.event_format_url">
                                        <div class="form-groupyes">
                                            <a target="_blank" href="{{participantDetailModel.value.event_details.event_format_url + utm_params}}" 
                                            class="btn btn-success btn-send  pt-2 btn-block">
                                                FORMAT
                                            </a>
                                        </div>
                                    </div>
                                    
                                
                                    
                                    <div class="col-md-12" *ngIf="participantDetailModel.value?.event_details?.event_panel_sessions_url">
                                        <div class="form-groupyes">
                                            <a target="_blank" href="{{participantDetailModel.value.event_details.event_panel_sessions_url + utm_params}}" 
                                            class="btn btn-success btn-send  pt-2 btn-block">
                                                SESSIONS
                                            </a>
                                        </div>
                                    </div>
                            
                                    
                                    <div class="col-md-12" *ngIf="participantDetailModel.value?.event_details?.event_sponsors_url">
                                        <div class="form-groupyes">
                                            <a target="_blank" href="{{participantDetailModel.value.event_details.event_sponsors_url + utm_params}}"
                                            class="btn btn-success btn-send  pt-2 btn-block">
                                                SPONSORS
                                            </a>
                                        </div>
                                    </div>
                                    
                            
                                    
                                    <div class="col-md-12" *ngIf="participantDetailModel.value?.event_details?.event_faqs_url">
                                        <div class="form-groupyes">
                                            <a target="_blank" href="{{participantDetailModel.value.event_details.event_faqs_url + utm_params}}" 
                                            class="btn btn-success btn-send  pt-2 btn-block">
                                                FAQs
                                            </a>
                                        </div>
                                    </div>
                                
                                
                                        
                                    
                                
                                </div>
                                
                        
                            
                                
                            
                                <div class="row">
                                    <div class="col-md-12"> 
                                        </div>  

                                    <div class="col-md-12 text-center">
                                        
                                    
                                    
                                </div>
                        
                                </div>  


                        </div>
            
                        </div>
                            </div>


                        </div>
            

                    </div>
            

               
                <div class="col-md-12  text-center">
                    <div class="form-group">
                        <img class="logoBl" src="../../../assets/images/logo.png">
                    </div>
                </div>
                    
                
            </div>
            
        </div>

</div>  




