

  
<div class="modal fade" id="sessionForm" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="sessionFormLabel" aria-hidden="true">
<div class="modal-dialog">
  <form [formGroup]="sessionForm" (ngSubmit)="onSubmit()">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="sessionFormLabel">{{sessionModel ? sessionModel.name:'Add Session'}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         
     
            <div class="form-group">
              <label for="name">Name *</label>
              <input type="text" class="form-control mt-1" id="name" formControlName="name" placeholder="Session Name">
              <div *ngIf="sessionForm.get('name')!.invalid && (sessionForm.get('name')!.dirty || sessionForm.get('name')!.touched)" 
              class="text-fix text-danger">
                <div *ngIf="sessionForm.get('name')!.errors?.['required']">Name is required.</div>
                <div *ngIf="sessionForm.get('name')!.errors?.['minlength']">Name must be at least 4 characters long.</div>
              </div>

            </div>

            <div class="form-group mt-3">
              <label for="description">Description *</label>
              <textarea name="description" id="description" class="form-control mt-1" rows="4" formControlName="description"
              placeholder="Session description"></textarea>
              <div *ngIf="sessionForm.get('description')!.invalid && (sessionForm.get('description')!.dirty || sessionForm.get('description')!.touched)" 
              class="text-fix text-danger">
                <div *ngIf="sessionForm.get('description')!.errors?.['required']">Description is required.</div>
                <div *ngIf="sessionForm.get('description')!.errors?.['minlength']">Name must be at least 10 characters long.</div>
              </div>
            </div>
          
            <label for="company" class="mt-3 mb-2">Type *</label>
            <ng-select placeholder="Select a type" formControlName="type">
              <ng-option *ngFor="let type of sessionTypes.value.results" [value]="type.id">{{type.name}}</ng-option>
           </ng-select>

           <div *ngIf="sessionForm.get('type')!.invalid && (sessionForm.get('type')!.dirty || sessionForm.get('type')!.touched)" 
           class="text-fix text-danger">
             <div *ngIf="sessionForm.get('type')!.errors?.['required']">Type is required.</div> 
           </div>
              


      </div>
      <div class="modal-footer">
        <button type="button" id="sessionModalCloseBtn" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary mt-3 ms-auto">{{sessionModel ? 'Update Session':'Add Session'}}</button> 
      </div>


    </div> 
  </form>
  </div>

</div>