import { Injectable } from "@angular/core";
import { BehaviorSubject, Observer } from "rxjs";
import { ApiService } from "../../api-providers/services/api-service";
import { ApiServiceDelegate } from "../../api-providers/services/api-service-delegate";
import { ApiCollection } from "../../api-providers/services/api-collection";
import { SessionModel, SessionResponseModel, SessionTypeResponseModel } from "../models/session.model";
import { SessionsComponent } from "src/app/admin/sessions/sessions.component";
import { ModelSessionFormComponent } from "src/app/components/model-session-form/model-session-form.component";
import { AlertService } from "../services/alert.service";
import { ModalDeleteComponent } from "src/app/components/modal-delete/modal-delete.component";
import { LoaderService } from "../services/loader.service";

@Injectable({
    providedIn: "root",
})

export class SessionsViewModel {

    parentSessionsComponent: SessionsComponent; 
    parentModelSessionFormComponent: ModelSessionFormComponent;
    parentModalDeleteComponent: ModalDeleteComponent;

    sessionsResponseModel = new BehaviorSubject<SessionResponseModel>(new SessionResponseModel()); 
    sessionTypes = new BehaviorSubject<SessionTypeResponseModel>(new SessionTypeResponseModel());

    constructor(private apiService: ApiService, private alertService: AlertService, private loaderService: LoaderService ) {
      
    }

    subscribeToViewModel(
        observer: Observer<SessionResponseModel>
    ){
        this.sessionsResponseModel.subscribe(observer);
    }

    subscribeToSessionTypes(
      observer: Observer<SessionTypeResponseModel>
  ){
      this.sessionTypes.subscribe(observer);
  }
 
    


    getSessions(search: string,page_index:number,page_size:number){
      this.loaderService.showLoading();
      var url = ApiCollection.Session + "?page="+page_index+"&page_size="+page_size;
      if(search.length > 0){
        url += '&name=' + search;
      }
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: url,
        Success: (e: any) => { 
          this.sessionsResponseModel.next(e);
          this.parentSessionsComponent.sessionsReceived();
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => {
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    // getSessionParticipantDetail(id: string, name: string){
    //   var url = ApiCollection.Participant + "?page=1&page_size=30" + "&session=" + id
    //   if(name.length > 0){
    //     url += "&name=" + encodeURIComponent(name);
    //   }
    //   let d: ApiServiceDelegate = {
    //     Data: {},
    //     Method: "GET",
    //     Action: url,
    //     Success: (e: any) => {
    //       this.sessionsDetailParticipantResponseModel.next(e);
    //       this.parentSessionDetailComponent.receivedSessionDeatil();
    //       return e;
    //     },
    //     Error: (e: String) => {
    //       return e;
    //     },
    //   };
    //   this.apiService.callService(d);
    // }

    getSessionTypes(){
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "GET",
        Action: ApiCollection.Session_Type,
        Success: (e: any) => {
          this.sessionTypes.next(e); 
          return e;
        },
        Error: (e: String) => {
          return e;
        },
      };
      this.apiService.callService(d);
    }

    createOrUpdateSession(sessionModel: SessionModel){
      this.loaderService.showLoading();
      var url = ApiCollection.Session;
      if(sessionModel.id != undefined){ url += '/' + sessionModel.id }
      let d: ApiServiceDelegate = {
        Data: sessionModel,
        Method: (sessionModel.id != undefined ) ? "PATCH":"POST",
        Action: url,
        Success: (e: any) => {
          this.parentModelSessionFormComponent.updatedSessionMedel(e);
          this.alertService.addAlert(sessionModel.id ? 'Session updated!':'Session created!', 'success');
          this.loaderService.hideLoading();
          return e; 
        },
        Error: (e: String) => { 
          this.alertService.addAlert(sessionModel.id ? "Unable to update session":'Session is not created!', 'danger');
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    deleteSession(id: string){
      this.loaderService.showLoading();
      let d: ApiServiceDelegate = {
        Data: {},
        Method: "DELETE",
        Action: ApiCollection.Session + '/' + id,
        Success: (e: any) => {
          this.parentModalDeleteComponent.deleteSuccess();
          this.alertService.addAlert('Session Deleted', 'success');
          this.loaderService.hideLoading();
          return e;
        },
        Error: (e: String) => {
          this.alertService.addAlert('Session is not deleted', 'danger');
          this.loaderService.hideLoading();
          return e;
        },
      };
      this.apiService.callService(d);
    }

    // getSessionById(caller: string, id: string){
    //   let d: ApiServiceDelegate = {
    //     Data: {},
    //     Method: "GET",
    //     Action: ApiCollection.Session + '/' + id,
    //     Success: (e: any) => {
    //       this.sessionResponse.next(e);
    //       this.parentManageSession.receivedSessionDeatil();
    //       return e;
    //     },
    //     Error: (e: String) => {
    //       return e;
    //     },
    //   };
    //   this.apiService.callService(d);
    // }


 


}
