 





    
 
 
    <div class="d-flex w-100 h-100 p-3 mx-auto flex-column wrapper">
 
     
        <div class="container mt-5 col-md-8 col-lg-8 col-12">
            <div class=" text-center mt-3 px-3 col-lg-12 justify-content-center align-items-center">

              
                                 
                <h3 class="text-left text-white"> 
                    Welcome to CommonSensen Conferences
                </h3>
                
            </div>


            <div class="row ">
                    <div class="col-lg-7 mx-auto mt-2">
                        <div class="card mt-2 mx-auto p-4 formbg">
                            <div class="card-body">
                    
                            <div class = "container"> 

                            <div class="controls">


                            

                                <div class="row"> 
                        
                                    <p class="text-white mt-2" style="font-size: 18px;">Common Sense high-value conferences showcase the real-world application of best practices in 
                                        Customer Experience, Artificial Intelligence, Marketing, and Digital Transformation. 
                                        Our <a class="text-decoration-none" href="https://www.commonsense.events/participants/">attendees</a> and 
                                        <a class="text-decoration-none" href="https://www.commonsense.events/host-branded-conversations/">sponsors</a> are creating the innovations that other companies will be studying next year.</p>
                                        
                                
                                        
                                    
                                
                                </div>
                                
                        
                            
                                
                            
                                <div class="row">
                                    <div class="col-md-12"> 
                                        </div>  

                                    <div class="col-md-12 text-center">
                                        
                                    
                                    
                                </div>
                        
                                </div>  


                        </div>
            
                        </div>
                            </div>


                        </div>
            

                    </div>
            

                    <div class="row">
                                    <div class="col-md-12  text-center">
                                        <div class="form-group">
                                            <img src="../../../assets/images/logo.png">
                                        </div>
                                    </div>
                    </div>
                
            </div>
            
        </div>

</div>  









