<div class="card shadow-sm h-100">
    <div class="row p-3">
        <div class="col-12 d-flex justify-content-start">
            <div>
                <img class="img-fluid shadow" src="../../../assets/avatar.svg" alt="" width="70px" height="70px">
            </div>
            <div class="ms-3">
                <p class="m-0"><strong>{{participantModel.first_name}} {{participantModel.last_name}}</strong> 
                <a class="ms-2" href="{{participantModel.linkedin_url}}" target="_blank"><i class="fa-brands fa-linkedin fa-1x"></i></a></p>
                <p class="m-0"> 
                    
                    <img *ngIf="participantModel.company_details.thumbnail" class="img-fluid" 
                    src="{{participantModel.company_details.thumbnail}}" alt="" width="18px" height="18px">
                    <i class="fa-regular fa-building mt-1" *ngIf="!participantModel.company_details.thumbnail"></i> 

                    {{participantModel.company_details.name ? 
                    participantModel.company_details.name:'Company not found'}}</p>
                    <p class="m-0" style="font-size: 12px;">{{participantModel.title ? participantModel.title:'Title not found'}}</p>

            </div> 
            <div class="ms-auto">
                <div>
                    <a class="cursor-pointer action-btn" (click)="downloadParticipantQR(participantModel)"><i class="fa-solid fa-qrcode"></i></a>
                </div>
                <div>
                    <a class="cursor-pointer action-btn"
                data-bs-toggle="modal" data-bs-target="#participantForm" 
                (click)="editParticipant()"><i class="fa-solid fa-pen-to-square"></i> </a>
                </div>
                <div>
                    <a class="cursor-pointer action-btn"
                data-bs-toggle="modal" data-bs-target="#deleteModal" 
                (click)="deleteParticipant(participantModel)" ><i class="fa-solid fa-trash-can"></i> </a>

                </div>
            </div>
        </div>

        <!-- 
             <button (click)="deleteParticipant(participantModel)" 
                    class="btn"><i class="fa-solid fa-trash-can"></i></button>
         -->

        <!-- <div class="col-12 mt-3 d-flex justify-content-between">
            <p *ngIf="participantEventDetailId.length > 0" class="m-0 mt-2">
                <i class="fa-solid fa-qrcode"></i> {{participantEventDetailId}}</p>
                <a href="javascript:void(0)" (click)="downloadParticipantQR()" class="btn ms-auto"><i class="fa-solid fa-download"></i></a>
        </div> -->
        <div class="col-12 mt-3">
            <p class="m-0"><i class="fa-regular fa-envelope"></i> {{participantModel.email}}</p>
        </div>
        <div *ngIf="participantModel.phone" class="col-12">
            <p class="m-0" *ngFor="let p of participantModel.phone"><i class="fa-solid fa-phone"></i> 
                ({{p.country_code_value}}) {{p.phone}}
            </p>
        </div> 
        
        
        <div class="col-12 mt-3 d-flex justify-content-start">
            <label class="switch">
                <input type="checkbox" [checked]="participantAttendance" 
                (change)="changeParticipantAttendance($event)"
                >
                <span class="slider round"></span>
              </label>
            <div  class=" mt-1 ms-3">
                <h6>Event Attendance <span class="text-success text-fix">: 
                    <span [ngClass]="participantAttendance ? 'badge text-bg-success':'badge text-bg-danger'">{{participantAttendance ? 'Attended':'Not Attended'}}</span>
                    </span></h6>
                
            </div>
           
        </div>

        <div class="col-12" *ngIf="participantAttendance">
            <div class=" d-flex justify-content-start mt-1" 
            *ngFor="let session of eventSessions">
            <label class="switch">
                <input type="checkbox"
                (change)="changeSessionAttendance($event, session.id)"
                [checked]="participantSessionsAttendance.includes(session.id)">
                <span class="slider round"></span>
                </label>

                <h6 class="ms-3">
                    {{session.name.length > 23 ? (session.name|slice:0:23) + '...':session.name}}
                    <!-- ({{session.type_name ? session.type_name: 'No API Data'}})  -->
                    <span class="text-success text-fix">: <span [ngClass]="participantSessionsAttendance.includes(session.id) ? 'badge text-bg-success':'badge text-bg-danger'">{{participantSessionsAttendance.includes(session.id) ? 'Attended':'Not Attended'}}</span>
                        
                        </span>
                </h6>
 
            
            </div>
           
        </div>

    </div>
    
</div>