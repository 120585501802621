import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from 'src/api-providers/services/api-service';
import { CompanyViewModel } from 'src/api-providers/view-models/company.view.model';
import { EventsViewModel } from 'src/api-providers/view-models/events.view.model';
import { ParticipantsViewModel } from 'src/api-providers/view-models/participants.view.model';
import { SessionsViewModel } from 'src/api-providers/view-models/sessions.view.model';

interface DeleteObject {
  name: string
  id: number
}

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
  providers: [CompanyViewModel, ParticipantsViewModel, SessionsViewModel, EventsViewModel, ApiService]
})
export class ModalDeleteComponent {

  deleteObject: DeleteObject;
  removeParticipantEventDetail: boolean = false;

  @Input() deleteType: string = '';
  @Output() modalDeleteEvent = new EventEmitter<any>();


  static StaticCall: any;

  constructor(
    private companyVM: CompanyViewModel,
    private participantVM: ParticipantsViewModel,
    private sessionVM: SessionsViewModel,
    private eventVM: EventsViewModel
  ){
    ModalDeleteComponent.StaticCall = this;

  }

  ngOnInit():void {


  }

  deleteCompany(){
    this.companyVM.parentModalDeleteComponent = this;
    this.companyVM.deleteCompany(String(this.deleteObject.id));
  }


  deleteParticipant(){
    this.participantVM.parentModalDeleteComponent = this;
    this.participantVM.deleteParticipant(String(this.deleteObject.id));
  }

  deleteSession(){
    this.sessionVM.parentModalDeleteComponent = this;
    this.sessionVM.deleteSession(String(this.deleteObject.id));
  }

  deleteEvent(){
    this.eventVM.parentModalDeleteComponent = this;
    this.eventVM.deleteEvent(String(this.deleteObject.id));
  }
  
  removeParticipantDetailFromEvent(){
    this.eventVM.parentModalDeleteComponent = this;
    this.eventVM.removeParticipantDetailFromEvent(String(this.deleteObject.id));
  }

  deleteConfirm(){
    if(this.deleteType == 'company'){
      this.deleteCompany();
    }

    if(this.deleteType == 'participant'){
      this.deleteParticipant();
    }

    if(this.deleteType == 'session'){
      this.deleteSession();
    }

    if(this.deleteType == 'event'){
      if(this.removeParticipantEventDetail === true){
        this.removeParticipantDetailFromEvent()
      } else {
        this.deleteEvent();
      }
    }
  }

  deleteSuccess(){
    document.getElementById("deleteModalCloseBtn")?.click();
    if(this.removeParticipantEventDetail){
      this.modalDeleteEvent.emit(false);
    } else {
      this.modalDeleteEvent.emit(true);
    }
      
  }

}
