import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventResponseModel } from 'src/api-providers/models/event.model';
import { ParticipantModel } from 'src/api-providers/models/participant.model';
import { ApiService } from 'src/api-providers/services/api-service';
import { EventsViewModel } from 'src/api-providers/view-models/events.view.model';

@Component({
  selector: 'app-modal-add-to-event',
  templateUrl: './modal-add-to-event.component.html',
  styleUrls: ['./modal-add-to-event.component.scss'],
  providers: [EventsViewModel, ApiService]
})
export class ModalAddToEventComponent {

  participantModel: ParticipantModel = new ParticipantModel();
  eventsResponseModel = new BehaviorSubject<EventResponseModel>(new EventResponseModel());
  selectedEvent: number | undefined;
  @Output() formSuccessEvent = new EventEmitter<any>();
  
  static StaticCall: any;

  constructor(
    private eventsVM: EventsViewModel
  ){
    ModalAddToEventComponent.StaticCall = this;
  }

  ngOnInit():void {
    this.eventsVM.parentModalAddToEventComponent = this;
    this.eventsVM.subscribeToEventsResponse(this.eventsResponseModel);
    this.getEvents();
  }
  
  getEvents(){
    var page_size = 100;
    var page = 1;
    var caller = 'add-to-events';
    this.eventsVM.getEvents('', page, page_size, caller);  
  }


  updateParticipant(participantModel: ParticipantModel){
    this.participantModel = participantModel;
  }

  removeParticipant(){
    this.selectedEvent = undefined;
    this.participantModel = new ParticipantModel();
  }

  eventReceived(){

  }

  addToEvent(){
    var item:any = this.selectedEvent;
    this.eventsVM.addParticipantToEvent(this.participantModel.id, item);
  }
  

  addedToEvent(){
    document.getElementById("addToEventCloseBtn")?.click();
    this.removeParticipant();
    this.formSuccessEvent.emit(true);
  }

  closeModalOnError(){
    document.getElementById("addToEventCloseBtn")?.click();
    this.removeParticipant();
    this.formSuccessEvent.emit(false);
  }


}
