

  
<div class="modal fade" id="addToEventModal" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="addToEventLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="addToEventLabel">Add to event</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="removeParticipant()"></button>
      </div>
      <div class="modal-body">

        
        <h4 class="text-muted"><i class="fa-regular fa-user"></i> {{participantModel.first_name}} {{participantModel.last_name}}</h4>
        
        <label for="form-label" class="text-fix text-primary fw-bold">Event: </label>
        <ng-select placeholder="Select event"  class="small-ng-select" [(ngModel)]="selectedEvent">
          <ng-option *ngFor="let item of eventsResponseModel.value.results"
          [value]="item.id">{{item.name}}</ng-option>
      </ng-select>
        
      <p class="mt-2">Participant will be added to selected event.</p>


      </div>
      <div class="modal-footer">
        <button type="button" id="addToEventCloseBtn" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger ms-auto" (click)="addToEvent()">Add</button>
      </div>
    </div>
  </div>
</div>