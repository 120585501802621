import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CompanyResponseModel } from 'src/api-providers/models/company.model';
import { ParticipantModel, PhoneCountryCodeResponseModel, PhoneInterface } from 'src/api-providers/models/participant.model';
import { ApiService } from 'src/api-providers/services/api-service';
import { ParticipantsViewModel } from 'src/api-providers/view-models/participants.view.model';



@Component({
  selector: 'app-modal-participant-form',
  templateUrl: './modal-participant-form.component.html',
  styleUrls: ['./modal-participant-form.component.scss'],
  providers: [ParticipantsViewModel, ApiService]
})
export class ModalParticipantFormComponent {

  
  participantModel!: ParticipantModel; 
  participantForm: FormGroup;
  @Output() formSuccessEvent = new EventEmitter<any>();
  companiesResponseModel = new BehaviorSubject<CompanyResponseModel>(new CompanyResponseModel());
  phoneCountryCodeResponseModel = new BehaviorSubject<PhoneCountryCodeResponseModel>(new PhoneCountryCodeResponseModel());

  @Input() eventId: number;
  
  phones: PhoneInterface[] = [];
  newPhone = new PhoneInterface() ;
  
  static StaticCall: any;

  constructor(
    private participantVM: ParticipantsViewModel,
  ) {
    ModalParticipantFormComponent.StaticCall = this;
    this.initFormFields();
  }

  ngOnInit():void {
    this.participantVM.parentModalParticipantsFormComponent = this;
    this.participantVM.subscribeToCompanyModel(this.companiesResponseModel);
    this.participantVM.subscribeToPhoneCodeModel(this.phoneCountryCodeResponseModel);
    this.participantVM.getPhoneCodes();
    this.getCompanies()
  }

  getCompanies(){
    this.participantVM.getCompanies()
  }

  getPhoneValue(id: number){
    var el = this.phoneCountryCodeResponseModel.value.results.find(el => el.id == id); 
    return el?.code
  }

  removePhone(item: PhoneInterface){
    const index = this.phones.indexOf(item);
    if (index !== -1) {
      this.phones.splice(index, 1);
    }
  }

  updateParticipantFields(participant: ParticipantModel){
    this.participantModel = participant;
    this.participantForm.setValue({ 
      company: this.participantModel.company,
      first_name: this.participantModel.first_name,
      last_name: this.participantModel.last_name,
      title: this.participantModel.title,
      email: this.participantModel.email,
      linkedin_url: this.participantModel.linkedin_url
    })
    this.phones = this.participantModel.phone || [];
  }

  initFormFields(){
    this.participantForm = new FormGroup({
      company: new FormControl(undefined, [Validators.required]),
      first_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
      last_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
      title: new FormControl('', [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.required, Validators.minLength(4)]),
      linkedin_url: new FormControl('', []),
    }); 
    this.phones = [];
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.participantForm.invalid) {
      this.validateAllFormFields(this.participantForm);
    } else {
      var newModel = new ParticipantModel();
      newModel = this.participantForm.value
      if(this.participantModel?.id){
        newModel.id = this.participantModel.id;  
      }        
      newModel.phone = JSON.stringify(this.phones) 
      if(this.eventId){
        newModel.event = [this.eventId]
      }
      this.participantVM.createOrUpdateParticipant(newModel);
    }
  }

  phoneAddedCancel(){
    this.newPhone = new PhoneInterface(); 
  }


  phoneAdded(){
    if(this.newPhone.country_code && this.newPhone.phone){
      var item: any = this.phoneCountryCodeResponseModel.value.results.find((el)=> el.id == this.newPhone.country_code);
      this.newPhone.country_code_value = item?.code;
      this.newPhone.country_name = item?.country;
      this.phones.push(this.newPhone);
    }
    this.newPhone = new PhoneInterface();
  }

  modalClosed(){
    this.formSuccessEvent.emit(false);
  }

  updatedParticipantMedel(participant: ParticipantModel){
    document.getElementById("participantModalCloseBtn")?.click();
    this.formSuccessEvent.emit(participant);
  }


}
