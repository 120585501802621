import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyModel } from 'src/api-providers/models/company.model';
import { CompanyViewModel } from 'src/api-providers/view-models/company.view.model';

@Component({
  selector: 'app-modal-company-form',
  templateUrl: './modal-company-form.component.html',
  styleUrls: ['./modal-company-form.component.scss']
})
export class ModalCompanyFormComponent {


  companyModel!: CompanyModel; 
  companyForm: FormGroup;
  @Output() formSuccessEvent = new EventEmitter<any>();
  
  static StaticCall: any;

  constructor(
    private companyVM: CompanyViewModel,
  ) {
    ModalCompanyFormComponent.StaticCall = this;
    this.initFormFields();
  }

  ngOnInit():void {
    this.companyVM.parentModalCompanyFormComponent = this;
  }



  updateCompanyFields(company: CompanyModel){
    this.companyModel = company;
    this.companyForm.setValue({ 
      name: company.name,
      website: company.website,
      linkedin_url: company.linkedin_url,
    })
  }

  initFormFields(){
    this.companyForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      website: new FormControl('', [Validators.required]),
      linkedin_url: new FormControl('', []),
    });  
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.companyForm.invalid) {
      this.validateAllFormFields(this.companyForm);
    } else {
      var newModel = new CompanyModel();
      newModel = this.companyForm.value
      if(this.companyModel?.id){
        newModel.id = this.companyModel.id;  
      }        
      this.companyVM.createOrUpdateCompany(newModel);
    }
  }

  updatedCompanyMedel(company: CompanyModel){
    document.getElementById("companyModalCloseBtn")?.click();
    this.formSuccessEvent.emit(company);
  }


}
