import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppCommon } from 'src/api-providers/app-common';
import { LoginModel } from 'src/api-providers/models/user.model';
import { ApiService } from 'src/api-providers/services/api-service';
import { LoginViewModel } from 'src/api-providers/view-models/login.view.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginViewModel, ApiService]
})
export class LoginComponent {
  currentYear = new Date().getFullYear();

  loginModel = new BehaviorSubject<LoginModel>(new LoginModel());
  loggedIn: boolean;
  googleUser: SocialUser;

  constructor(
    private vm: LoginViewModel,
    private router: Router,
    private authService: SocialAuthService, 
  ) { 
  }

  ngOnInit() {
    this.vm.parentLoginComponent = this;
    this.vm.subscribeLogin(this.loginModel);
    this.authService.authState.subscribe((user) => {
      this.googleUser = user;
      AppCommon.setGoogleUserObject(this.googleUser);
      if(this.googleUser){
          this.loginWithGoogle();
      };
      this.loggedIn = (user != null);
    });
  }

  loginWithGoogle(): void { 
    this.vm.authenticate(this.googleUser.idToken);
  }

  loginSuccess(){
    this.router.navigate(["/admin/events"]);
  }

  
}
